import { useI18n } from "vue-i18n";
import { useNotification } from "@/hooks/notifications";
import { useStore } from "vuex";
import { stripHTMLTagsFromString } from "@/helpers/common";

interface CopyOptions {
  stringify?: boolean;
  success?: string;
  error?: string;
  isRichText?: boolean;
}

export const useClipboardWithMessage = () => {
  const { showMessage } = useNotification();
  const { t } = useI18n();
  const { commit } = useStore();

  const DEFAULT_MESSAGES = {
    success: t("COMMON.COPIED"),
    error: t("COMMON.ERROR_OCCURRED")
  };

  return async <T>(value: T, options: CopyOptions = {}) => {
    if (value === null || value === undefined) {
      return;
    }
    const mergedOptions: CopyOptions = {
      // Default options
      stringify: false,
      ...DEFAULT_MESSAGES,
      ...options
    };
    try {
      if (options.isRichText) {
        const clearText = stripHTMLTagsFromString(
          value as unknown as string,
          false
        );
        const html = new Blob([value as unknown as string], {
          type: "text/html"
        });
        const text = new Blob([clearText], {
          type: "text/plain"
        });
        if (typeof ClipboardItem !== "undefined") {
          const data = new ClipboardItem({
            "text/html": html,
            "text/plain": text
          });
          await navigator?.clipboard.write([data]);
        } else {
          await navigator?.clipboard.writeText(clearText);
        }
        showMessage(options.success || t("COMMON.COPIED"), "success");
        return;
      }
      const data = mergedOptions.stringify
        ? JSON.stringify(value)
        : (value as unknown as string);
      // Copy to clipboard in the next loop for Safari
      setTimeout(async () => {
        await navigator?.clipboard.writeText(data);
      }, 0);
    } catch (error) {
      if (
        (error as { value?: string }).value &&
        ((error as { value?: string }).value?.length || 0) < 200
      ) {
        // Fallback for Safari - show alert with the link
        alert((error as { value: string }).value);
        return;
      } else {
        commit("setGlobalMessage", {
          title: t("COMMON.ERROR_OCCURRED"),
          type: "error"
        });
      }
      showMessage("An unknown error occurred", "error");
      return;
    }
    showMessage(options.success || t("COMMON.COPIED"), "success");
  };
};
